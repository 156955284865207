(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('TemplateController', TemplateController);

    TemplateController.$inject = ['$state', '$scope', '$stateParams', '$q', '$localStorage', 'Qinius', 'Theme',
    'ProductXml', 'ToneSettings', '$cookies', '$rootScope', 'Product'];

    function TemplateController($state, $scope, $stateParams, $q, $localStorage, Qinius, Theme,
     ProductXml, ToneSettings, $cookies, $rootScope, Product) {

        var vm = this;

        $(".navbar-gallery").hide();
        $(".wrap").show().addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        vm.showDetail = false;
        // vm.product = entity;
        vm.chooseTheme = chooseTheme;
        vm.changeImgStyle = changeImgStyle;
        var num = 0;
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        vm.pid = $stateParams.pid;
        vm.vid = $stateParams.vid;
        vm.aid = $stateParams.aid;
        $localStorage.article = {id:vm.aid};
        $scope.selectMenuShow = false;
        console.log(vm.vid)
        //风格切换  引入不同的css文件
        Theme.judge();

        $scope.imgClick = function (event) {
            if (event == null || event.originalEvent == null || event.currentTarget == null) {
                return;
            }
            if (event.originalEvent.layerX > event.currentTarget.clientWidth / 2) {
                $scope.nextImg();
            } else {
                $scope.prevImg();
            }

        }

        $scope.screenWidth = window.screen.width;
        vm.domains = Qinius.getDomain();
        vm.product = Product.get({id: $stateParams.pid});
        vm.width = $(window).width();
        var init = 0;

        var unsubscribe = $rootScope.$on('changeLanguage', function(event, result) {
            vm.product = Product.get({id: $stateParams.pid});
            $q.all([vm.product.$promise, vm.domains.$promise]).then(function () {
                $scope.init();
                vm.chooseTheme(null, -1);
            });
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.init = function(){
            vm.product = vm.product.data;
            vm.lagnuages =[];
            if ($localStorage.lagnuages) {
                for(var i = 0 ; i<$localStorage.lagnuages.length; i++ ){
                    var lang = $localStorage.lagnuages[i];
                    var langObj = {key:lang};
                    if (lang == 'zh-cn') {
                        langObj.value = "中文";
                    }
                    if (lang == 'en') {
                        langObj.value = "English";
                    }
                    if (vm.lang == lang) {
                        vm.langValue = langObj.value;
                        langObj.selected = true;
                    }
                    vm.lagnuages.push(langObj);
                }
            }
            // 屏幕尺寸
            vm.windowWidth = $(window).width();
            vm.windowHeight = $(window).height() + 'px';
            vm.len = 0;
            for (var i = 0; i < vm.product.itemValues.length; i++) {
                var itemValue = vm.product.itemValues[i];
                if (itemValue.cancelled) {
                    continue;
                }
                vm.len++;
                if (itemValue.id == vm.vid) {
                    vm.itemValueOne = angular.copy(itemValue);
                    vm.itemValueOneCopy = angular.copy(itemValue);
                }
            }
            vm.themeList = [];
            if (vm.itemValueOne != null && vm.itemValueOne.productXmlLines != null && vm.itemValueOne.productXmlLines.length > 0) {
                vm.itemValueOne.productXmlLines.sort(function (a, b) {
                    return a.seq - b.seq;
                });
                for (var i = 0; i < vm.itemValueOne.productXmlLines.length; i++) {
                    var line = vm.itemValueOne.productXmlLines[i];
                    if (!line.themeClassifies || line.cancelled||!line.paid) {
                        continue;
                    }
                    line.themeClassifies.forEach(function (themeClassifie) {
                        if(!checkRepetition(themeClassifie.id)){
                            vm.themeList.push({
                                id: themeClassifie.id,
                                name: themeClassifie.name,
                                seq: Number(line.productXmlThemeSeq) + Number(themeClassifie.seq)
                            });
                        }
                    })
                }
            }
            vm.themeList.sort(function (a, b) {
                return a.seq - b.seq;
            })

            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            getToneSettings();
        }

        $q.all([vm.product.$promise, vm.domains.$promise]).then(function () {
            $scope.init();
        });

        function getToneSettings() {
            ToneSettings.byStoreId({id:vm.storeId}, function (res) {
                if(res.status == 200){
                    if(res.data){
                        vm.toneSettings = res.data;
                        console.log(vm.toneSettings.logo)
                        for(var key in  res.data){
                            if(res.data[key]){
                                document.body.style.setProperty('--' + key, res.data[key]);
                            }
                        }
                    }
                }else{
                    swal({
                        title: res.message,
                        text: "",
                        showConfirmButton: false,
                        type: "info"
                    });
                }
            }, function (error) {});
        }

        // 检查是否存在重复--vm.themeList
        function checkRepetition(id) {
            if (vm.themeList.length == 0) {
                return false;
            } else {
                for (var i = 0; i < vm.themeList.length; i++) {
                    if (vm.themeList[i].id == id) {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.closeModal = function () {
            $scope.selectMenuShow = !$scope.selectMenuShow;
        }

        $scope.prevImg = function () {
            if (init > 0) {
                init--;
            } else {
                return;
            }
            document.getElementById("top-img").src = vm.imgs[init].identifier + '?imageView2' || '//:0';
        };
        $scope.nextImg = function () {
            if (init < (num - 1)) {
                init++;
            } else {
                return;
            }
            document.getElementById("top-img").src = vm.imgs[init].identifier + '?imageView2' || '//:0';
        };

        $scope.valueSeleted = function (value) {
            vm.productXmlLine = value;
            if (value.xmlShowPic) {
                //图片预览出来
                vm.xmlShowPic = true;
                ProductXml.get({id: vm.productXmlLine.productXmlId}, function (res) {
                    if(res.status == 200){
                        if (res.data.documentPics && res.data.documentPics.length > 0) {
                            res.data.documentPics.sort(function (a, b) {
                                return a.seq - b.seq;
                            });
                        }
                        vm.imgs = res.data.documentPics;
                        num = vm.imgs.length || 0;
                    }else {
                        MessageService.error(res.message);
                    }
                }, function () {

                })
            } else {
                $scope.productChoose();
            }
        };

        $scope.previewClose = function () {
            init = 0;
            vm.xmlShowPic = false;
        };

        var startMade = function (xmlId) {
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
            localStorage.removeItem("isSort");
            var workSave = JSON.parse(localStorage.getItem('work-save'));
            if (workSave) {
                var nearHour = (new Date().getTime() - workSave.date) / 1000 / 3600;
                console.log(nearHour);
                if (workSave.pid == vm.pid && workSave.vid == vm.vid && (workSave.xmlId == xmlId || workSave.xmlId == "null" && xmlId == null) && nearHour <= 3) {
                    return
                }
            }

            localStorage.setItem("Production", "start");
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("go");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("$index");
            localStorage.removeItem("next");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("work-save");
            localStorage.setItem('countN', 1);

            var gallerys = JSON.parse(localStorage.getItem('gallery'));
            angular.forEach(gallerys, function (gallery) {
                gallery.useNum = 0;       //次数清零
            });
            localStorage.setItem('gallery', JSON.stringify(gallerys));
        };

        $scope.productChoose = function () {
            var xmlId = vm.productXmlLine.id;

            startMade(xmlId);
            debugger
            if (vm.product.editorType == null || vm.product.editorType == "" || vm.product.editorType == "BOOK_SENIOR") {
                $state.go('editorPhotoAlbumM', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            } else if (vm.product.editorType == "PICTURE" || vm.product.editorType == "PICTURE_PRO") {
                $state.go('editorPhotoBoth', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            }   else if (vm.product.editorType == "BOOK_JUNIOR") {
                $state.go('editorPhotoAlbumM', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            }  else if (vm.product.editorType == "PHOTO_EDITOR") {
                $state.go('idPhoto', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            } else if (vm.product.editorType == "POSTER" || vm.product.editorType == "DIY_EDITOR") {
                $state.go('product-chooseThree-ec', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            } else if (vm.product.editorType == "FORM_EDITOR") {
                $state.go('editorForm', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            } else if (vm.product.editorType == "NEW_EDITOR"||vm.product.editorType == "CALENDAR_EDITOR") {
                $state.go('editorPhotoAlbumM', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            } else {
                $state.go('editorPhotoAlbumM', {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: xmlId, aid: vm.aid})
            }
        };
        function isIphoneX() {
            return /iphone/gi.test(navigator.userAgent) && (screen.height == 812 && screen.width == 375)
        }

        $scope.isIphoneX = isIphoneX();

        var windowH = $(window).height();
        if (localStorage.getItem('windowH')) {
            windowH = localStorage.getItem('windowH')
        }

        $(window).scroll(function () {
            if ($scope.isIphoneX) {
                var wh = $(window).height(); //页面可视化区域高度
                if (wh > windowH) {
                    $(".iphoneX-content").height(36);
                } else {
                    $(".iphoneX-content").height(0);
                }
            }
        });

        var theme_index = -1;

        function chooseTheme(name, index) {
            if (index == -1) {
                // vm.itemValueOne = angular.copy(vm.itemValueOneCopy);
                if (vm.searchText) {
                    vm.itemValueOne.productXmlLines = [];
                    vm.oldThemeList = angular.copy(vm.itemValueOneCopy.productXmlLines);
                    vm.itemValueOne.productXmlLines = vm.fuzzyQuery(vm.oldThemeList , vm.searchText)
                }else{
                    vm.itemValueOne = angular.copy(vm.itemValueOneCopy);
                }
                if (vm.itemValueOne.productXmlLines.length) {
                    vm.itemValueOne.productXmlLines.sort(function(a, b){
                        return a.seq-b.seq;
                    })
                }

                $("#themeDiv_00").addClass("bc-ff9803");
                $("#themeDiv_" + theme_index).removeClass("bc-ff9803");
                $("#themeDiv0_" + theme_index).removeClass("bc-ffffff");

                $("#themeDiv0_00").removeClass("bc-fafafa");
                $("#themeDiv0_00").addClass("bc-ffffff");
                theme_index = -1;
            } else {
                if (theme_index == -1) {
                    $("#themeDiv_00").removeClass("bc-ff9803");
                    $("#themeDiv_00").removeClass("bc-fafafa");

                    $("#themeDiv0_00").removeClass("bc-ffffff");
                    $("#themeDiv0_00").addClass("bc-fafafa");
                } else {
                    $("#themeDiv_" + theme_index).removeClass("bc-ff9803");
                    $("#themeDiv0_" + theme_index).removeClass("bc-ffffff");
                    $("#themeDiv0_" + theme_index).addClass("bc-fafafa");
                }
                theme_index = index;
                $("#themeDiv_" + theme_index).addClass("bc-ff9803");
                $("#themeDiv0_" + theme_index).addClass("bc-ffffff");

                if (vm.searchText) {
                    vm.itemValueOne.productXmlLines = [];
                    vm.oldThemeList = [];
                    if (vm.itemValueOneCopy != null && vm.itemValueOneCopy.productXmlLines != null) {
                        var line = vm.itemValueOneCopy.productXmlLines;
                        for (var i = 0; i < line.length; i++) {
                            if(!line[i].notAvailable&&!line[i].cancelled&&line[i].paid){
                                var _ise = false;
                                line[i].themeClassifies.forEach(function (themeClassifie) {
                                    if (name == themeClassifie.name) {
                                        _ise = true;
                                    }
                                })
                                if (_ise) {
                                    vm.oldThemeList.push(line[i])
                                }
                            }
                        }
                    }
                    vm.itemValueOne.productXmlLines = vm.fuzzyQuery(vm.oldThemeList , vm.searchText)
                }else{
                    vm.itemValueOne.productXmlLines = [];
                    if (vm.itemValueOneCopy != null && vm.itemValueOneCopy.productXmlLines != null) {
                        var line = vm.itemValueOneCopy.productXmlLines;
                        for (var i = 0; i < line.length; i++) {
                            if(!line[i].notAvailable&&!line[i].cancelled&&line[i].paid){
                                var _ise = false;
                                line[i].themeClassifies.forEach(function (themeClassifie) {
                                    if (name == themeClassifie.name) {
                                        _ise = true;
                                    }
                                })
                                if (_ise) {
                                    vm.itemValueOne.productXmlLines.push(line[i]);
                                }
                            }
                        }
                        vm.itemValueOne.productXmlLines.sort(function(a, b){
                            return a.seq-b.seq;
                        })
                    }
                }
            }
        }

        function changeImgStyle(imgId) {
            var div0 = document.getElementById('div_' + imgId);
            console.log(div0.offsetHeight);
            if (div0.offsetHeight >= 150) {
                document.getElementById('div0_' + imgId).style.height = 201 + "px";
            } else if (div0.offsetHeight <= 105) {
                document.getElementById('div0_' + imgId).style.height = 150 + "px";
            } else {
                document.getElementById('div0_' + imgId).style.height = 191 + "px";
            }

            // 作无效返回
            return 0 + "px";
        }

        $scope.clearSearchText = function () {
            vm.searchText = '';
            vm.haveFocus = false;
            vm.search = false;
            vm.itemValueOne.productXmlLines = vm.oldThemeList
        }
        vm.oldThemeList = []
        $scope.searchMethodClick = function(){
            if (!vm.searchText) {
                return
            }
            vm.oldThemeList = vm.itemValueOne.productXmlLines
            vm.itemValueOne.productXmlLines = vm.fuzzyQuery(vm.itemValueOne.productXmlLines , vm.searchText)
        }
        vm.fuzzyQuery = function(list, keyWord) {
            if (keyWord != "") {
                var reg = new RegExp(keyWord);
                var arr = [];
                for (var i = 0; i < list.length; i++) {
                    if (reg.test(list[i].name)) {
                        arr.push(list[i])
                    }
                }
                return arr;
            } else {
                return;
            }
        }
    }
})();
