(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('Template2Controller', Template2Controller);

    Template2Controller.$inject = ['$state', 'AuthServerProvider', '$stateParams', 'Article', 'StoreWorkEditConfig', 'Product', 'Editors','$localStorage'];

    function Template2Controller($state, AuthServerProvider, $stateParams, Article, StoreWorkEditConfig, Product, Editors,$localStorage) {

        // {storeId}/{pid}/{vid}/{aid}
        $(".navbar").hide();
        $(".wrap").hide();
        var storeId = $stateParams.storeId;
        var pid = $stateParams.pid;
        var vid = $stateParams.vid;
        var aid = $stateParams.aid;
        localStorage.removeItem('variableData');
        var variableData = $stateParams.variableData;
        if(variableData){
            localStorage.setItem('variableData', variableData);
        }
        localStorage.setItem('themeFlag', 'two');
        //清除即时保存数据
        localStorage.removeItem('save-article');
        console.log($stateParams)

        //对escape()编码的字符串进行解码
        function getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if(r != null){
                return unescape(r[2]);
            }
            return null;//如果此处只写return;则返回的是undefined
        };
        var str = getQueryString("returnurl");
        var str1 = getQueryString("returnurl1");
        var str2 = getQueryString("returnurl2");
        var source = getQueryString("source");
        if(str){
            localStorage.setItem('returnurl', window.decodeURIComponent(str));
        }else{
            localStorage.removeItem('returnurl')
        }
        if(str1){
            localStorage.setItem('returnurl1', window.decodeURIComponent(str1));
        }else {
            localStorage.removeItem('returnurl1')
        }

        if(str2){
            localStorage.setItem('returnurl2', window.decodeURIComponent(str2));
        }else {
            localStorage.removeItem('returnurl2')
        }
        if(source){
            localStorage.setItem('source', window.decodeURIComponent(source));
        }else {
            localStorage.removeItem('source')
        }
        function isMobile(){
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }
        var isMobile = isMobile();
        // return

        if ($stateParams.auth) {
            AuthServerProvider.storeAuthenticationToken($stateParams.auth, true);
            init();
        }
        else if ($stateParams.ticket) {
            var token = getToken($stateParams.ticket, function(token) {
                if (token) {
                    AuthServerProvider.storeAuthenticationToken(token, true);
                    init();
                } else {
                    // illegal
                    console.error("Cannot open article with failure of getToken");
                }
            });
        } else {
            // illegal
            console.error("Cannot open article with nothing ticket or auth");
        }

        function getToken(ticket, callback) {
            Article.getToken({ticket: ticket}, function (res) {
                if (res.status == 200) {
                    var token = res.data;
                    if (token && callback) {
                        callback(token);
                    } else {
                        console.log("Invalid token or callback, token=" + token);
                    }
                } else {
                    console.log("Error to getToken with ticket: " + ticket + ", article=" + aid);
                }
            });
        }

        function init() {
            Article.get({id: aid},function (res) {
                if(res.status==200){
                    $localStorage.article = res.data;
                    if(res.data.invalid){//作品失效
                        $state.go('articlesFailure',{storeId:storeId});
                        return
                    }
                    Product.get({id: pid}, function (res1) {
                        var product = res1.data;
                        var data = res.data;
                        //进入新建页面
                        if (data.articleStatus=='NEW'){
                            StoreWorkEditConfig.storeId(function (msg) {
                                if (msg.status == 200) {
                                    // sku
                                    if(msg.data.level == 'ItemValue'){
                                        selectXml(data);
                                    }else{
                                        // 模板行
                                        if(!isMobile){
                                            if (data.editorType == "PICTURE" || data.editorType == "PICTURE_PRO") {
                                                $state.go("editorPhotoBothWeb", {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid});
                                            }else{
                                                $state.go("editorPhotoAlbum", {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid});
                                            }
                                        }else{
                                            localStorage.setItem("Production", "start");
                                            localStorage.removeItem("pages");
                                            localStorage.removeItem("article-count");
                                            localStorage.removeItem("work-save");
                                            if (data.editorType == "PICTURE" || data.editorType == "PICTURE_PRO") {
                                                $state.go("editorPhotoBoth", {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid},{reload: true});
                                                return
                                            }
                                            if (data.editorType == 'BOOK_JUNIOR') {
                                                $state.go('product-choose-ec', {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid})
                                            } else if (data.editorType == null || data.editorType == "" || data.editorType == 'BOOK_SENIOR') {
                                                $state.go('product-chooseNew-ec', {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid})
                                            } else if (data.editorType == 'NEW_EDITOR'||data.editorType == 'CALENDAR_EDITOR') {
                                                $state.go('editorPhotoAlbumM', {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid})
                                            } else if (data.editorType == "FORM_EDITOR") {
                                                $state.go('editorForm', {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid})
                                            } else if (data.editorType == "PHOTO_EDITOR") {
                                                $state.go('idPhoto', {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid})
                                            }else{
                                                $state.go('product-chooseThree-ec', {storeId: storeId, pid: pid, vid: data.itemValueId, xmlId: vid, aid: aid})
                                            }
                                        }
                                    }
                                }
                            })
                        }else{
                            // 进入编辑页面
                            if(!isMobile){
                                if(data.editorType=='PICTURE' || data.editorType=='PICTURE_PRO'){
                                    $state.go("editorPhotoBothWebEdit",{storeId:storeId,aid:aid})
                                }else{
                                    $state.go('editorPhotoAlbumEdit', {storeId:storeId,aid:aid})
                                }
                            }else{
                                localStorage.setItem("Production", "view");
                                if (data.editorType == 'BOOK_JUNIOR') {
                                    $state.go('editorPhotoAlbumEditM', {storeId: storeId, aid: aid}, {reload: true});
                                } else if (data.editorType == "PHOTO_EDITOR") {
                                    $state.go("idPhotoEdit", {storeId: storeId, aid: aid});
                                }  else if (data.editorType == "PICTURE" || data.editorType == "PICTURE_PRO") {
                                    $state.go("editorPhotoBothEdit", {storeId: storeId, aid: aid});
                                } else if (data.editorType == null || data.editorType == "" || data.editorType == 'BOOK_SENIOR') {
                                    $state.go('editorPhotoAlbumEditM', {storeId: storeId, aid: aid}, {reload: true});
                                } else if (data.editorType == 'NEW_EDITOR'||data.editorType == 'CALENDAR_EDITOR') {
                                    $state.go('editorPhotoAlbumEditM', {storeId: storeId, aid: aid}, {reload: true});
                                }else{
                                    $state.go('product-choosesThree-ec', {storeId: storeId, aid: aid}, {reload: true});
                                }
                            }
                        }
                    })
                }
            })
        }



        function selectXml(data) {
            //web 端
            Product.get({id: pid}, function (res) {
                if(!isMobile){
                    Editors.navigateSelect(storeId, {pid:pid, vid:vid, aid:aid, mobile: isMobile, selectState: 'commoditySelect'})
                }else{
                    Editors.navigateSelect(storeId, {pid:pid, vid:vid, aid:aid, mobile: isMobile, selectState: 'thumbnail'})
                }
            })
        }
    }
})();
